body{
  font-family: 'Roboto', sans-serif;
  color: #000000;
}

.header-1{
  margin: 24px 0px 8px 0px;
  padding: 0px;
  font-size: 25px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}

.categoryCol {
  padding: 0.5em 0.5em 0.5em 0.5em;
}

.breadcrumbCol {
  padding: 32px 12.8px 3.2px 12.8px;
}

.breadcrumbCol-2 {
  padding: 3.2px 12.8px 3.2px 12.8px;
}

.productCol {
  padding: 1em 0.5em 1em 0.5em;
}

.textDecorationNone {
  text-decoration: none;
}

.textDecorationNone:hover .categoryButton {
  visibility: visible;
}

.textDecorationNone:hover .categoryButton:hover {
  background-color: #e35600;
  border: 0.1em solid #e35600;
  color: #ffffff;
}

.categoryItem {
  border: 0.1em solid #dfdfdf;
  border-radius: 0.2em;
  transition: 0.2s;
  text-align: center;
}

.categoryItem:hover {
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
}

/*****************************************************/

td{
  text-align: center;
  font-size: 0.95em;
}

th{
  text-align: center;
  font-size: 0.95em;
}

.colForTable{
  margin-bottom: 1em;
  max-height: 19.5em;
  overflow: auto;
}

/********* Header *********/

.logo{
  width: 8.75em;
}

#headerItem {
  margin-left: 0.2em;
  font-size: 14.4px;
  font-weight: 500;
  color: #000000;
  transition: 0.4s;
}

@media (min-width: 1400px) {
  #headerItem {
    margin-left: 1.5em;
  }
}

#headerItem:hover {
  color: #ff6f00;
}

/********* Footer *********/

footer {
  margin-top: 3em;
  padding: 3em 1em 3em 1em;
  background-color: #252525;
  text-align: center;
}

.footerCol {
  padding: 0.5em;
}

.footerItem {
  margin: 0.1em;
  font-size: 0.9em;
  font-weight: 500;
  text-decoration: none;
  color: #858585;
  transition: 0.4s;
}

.footerItem:hover {
  color: #e5e5e5;
}

/********* ContactScreen *********/

.contactCol {
  text-align: center;
  padding: 1em 2em 1em 2em;
}

.contactItem {
  margin: 0em;
  text-decoration: none;
  color: #000000;
}

.contactItem:hover {
  color: #000000;
}

.mailIcon {
  color: #007ae7;
}

.phoneIcon {
  color: #ee0000;
}

.wpIcon {
  color: #4bca5a;
}

.socialIcon {
  margin: 1.5em;
  color: #000000;
  transition: 0.4s;
}

.socialIcon:hover {
  color: #555555;
}

iframe {
  margin-top: 1em;
  width: 100%;
  height: 20em;
}

/********* ReferenceScreen *********/

.referenceRow {
  padding: 0em 0em 0em 0em;
}

@media (min-width: 992px) {
  .referenceRow {
    padding: 0em 2.5em 0em 2.5em;
  }
}

.referenceCol {
  text-align: center;
  padding: 0.1em 1.5em 0.1em 1.5em;
}

.referenceImage{
  width: 100%;
}

/********* CategoryScreen *********/

.categoryImage {
  padding: 0.2em 0em 0.1em 0em;
}

.categoryHeader {
  padding: 0.1em 0.4em 0.1em 0.4em;
  font-size: 1em;
  font-weight: 400;
  color: #000000;
  min-height: 2.6em;
}

@media (min-width: 392px) {
  .categoryHeader {
    padding: 0.1em 0.8em 0.1em 0.8em;
    font-size: 1.1em;
  }
}

@media (min-width: 992px) {

  .categoryImage {
    padding: 0.3em 0.9em 0.3em 0.9em;
  }

  .categoryHeader {
    padding: 0em;
    font-size: 1.1em;
    min-height: auto;
  }
}

/********* ProductsScreen *********/

.techinacalData{
  font-size: 14px;
  margin: 5px;
}

.categoryImage-2 {
  padding: 0.6em 0em 0.6em 0em;
  transition: 0.2s;
  width: 100%;
}

.categoryImage-3 {
  padding: 0em 0.6em 0em 0.6em;
  transition: 0.2s;
  width: 100%;
}

@media (min-width: 992px) {
  .categoryImage-2 {
    padding: 0.5em 0em 0.5em 0em;
    width: 80%;
  }

  .categoryImage-3 {
    padding: 0em 1.2em 0em 1.2em;
    width: 75%;
  }
}

.categoryHeader-2 {
  padding: 0.1em 0.4em 0.1em 0.4em;
  font-size: 1em;
  font-weight: 700;
  color: #000000;
}

.categoryP {
  margin-left: 0.8em;
  margin-right: 0.8em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000000;
  font-weight: 400;
}

.categoryButton {
  font-size: 1em;
  background-color: #ff6f00;
  border: 0.1em solid #ff6f00;
  color: #ffffff;
  margin: 0.2em 0em 0.4em 0em;
  padding: 0.2em 2em 0.2em 2em;
  visibility: hidden;
  border-radius: 0.3em;
}

@media (max-width: 1000px) {
  .categoryButton {
    display: none;
  }
}

.categoryDescriptionCol {
  border-radius: 0.2em;
  margin-top: 2em;
  padding: 1em 1.4em 0.2em 1.4em;
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.1);
}

.categoryDescriptionP {
  line-height: 2em;
  color: #000000;
}

/********* ProductScreen *********/
.tabHeader{
  margin-left: 0.2em;
  font-weight: 500;
  font-size: 1.2em;
}

.customTable {
  background-color: #dfdfdf;
  border-color: #ffffff;
  color: #000000;
  text-shadow: 0.01em 0.01em #000000;
}

.errorRow {
  padding: 3em 1em 12em 1em;
}

.productError {
  padding: 2.5em;
  background-color: #f7dddc;
  border: 0.1em solid #ae150c;
  border-radius: 0.3em;
  text-align: center;
}

.productErrorP {
  margin: 0em;
  font-size: 2em;
  color: #000000;
  text-shadow: 0.02em 0.02em #000000;
}

.error404P {
  margin: 0em;
  font-size: 6em;
  color: #000000;
  text-shadow: 0.02em 0.02em #000000;
}

.productCol-2 {
  margin: 2em 0em 0em 0em;
  padding: 1em 0.5em 0.5em 0.5em;
}

.customTabs{
  padding: 0.4em 0.4em 0.4em 0.4em;
  border-radius: 0.2em 0.2em 0.2em 0.2em;
  border: 0.1em solid #dfdfdf;
}

.equipmentP{
  margin: 0em;
  padding: 0em;
}

.productCol-3 {
  border-bottom: 0.1em solid #000000;
  margin-bottom: 1em;
}

.productOverviewP {
  margin: 0.6em 0em 0.6em 0em;
  padding: 0em 0.4em 0em 0.4em;
}

.productOverviewP-2 {
  font-weight: 500;
}

.productInfoP {
  white-space: pre-wrap;
  color: #000000;
}

.productInfoP-2 {
  margin-top: 2em;
  margin-bottom: 0.5em;
  font-size: 1.2em;
  color: #000000;
}

.productInfoTable {
  font-size: 0.9em;
}

.productInfoTable-2 {
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  vertical-align: middle;
}

.productInfoTable-3 {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}

.accessoryTableImage{
  height: 100px;
}

.productDatasheet {
  color: #b30b00;
  padding: 0.6em 0.3em 0.6em 0.3em;
}

.productCarousel {
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.2em;
}

.carouselItem {
  padding: 0.5em 4em 0.5em 4em;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 5px;
  margin-right: 5px;
  margin-left: 5px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (min-width: 1400px) {
  .carouselItem {
    padding: 0.5em 10em 0.5em 10em;
  }
}

.productHeader {
  font-size: 1.4em;
  font-weight: 500;
  color: #000000;
}

.productHeader-2 {
  margin: 0em;
  font-size: 0.8em;
  font-weight: 500;
  color: #000000;
  text-transform: uppercase;
}

.productOverview {
  font-size: 1.2em;
  font-weight: 400;
  color: #000000;
}

.table {
  color: #000000;
}

.requestCol{
  margin: 0.6em 0.4em 0.4em 0.4em;
  display: inline-block;
}

.requestHeader {
  color: #ffffff;
}

.requestHeader:hover {
  color: #ffffff;
}

.requestButton {
  background-color: #ff6f00;
  border: 0.1em solid #ff6f00;
  border-radius: 0.2em;
  transition: 0.4s;
  color: #ffffff;
}

.requestButton:hover,
.requestButton:focus {
  background-color: #ff6f00;
  border: 0.1em solid #ff6f00;
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.4);
}

.requestWhatsappButton {
  background-color: #4bca5a;
  border: 0.1em solid #4bca5a;
  border-radius: 0.2em;
  transition: 0.4s;
  color: #ffffff;
}

.requestWhatsappButton:hover,
.requestWhatsappButton:focus {
  background-color: #4bca5a;
  border: 0.1em solid #4bca5a;
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.4);
}

.requestPdf {
  background-color: #b30b00;
  border: 0.1em solid #b30b00;
  border-radius: 0.2em;
  transition: 0.4s;
  color: #ffffff;
}

.requestPdf:hover,
.requestPdf:focus {
  background-color: #b30b00;
  border: 0.1em solid #b30b00;
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.4);
}

/********* ServiceScreen - AboutScreen *********/

.aboutCol {
  padding: 1em 5em 2em 5em;
}

@media (max-width: 1200px) {
  .aboutCol {
    padding: 1em 1.5em 2em 1.5em;
  }
}

.aboutP {
  line-height: 2em;
  color: #000000;
}

.aboutImage {
  width: 100%;
  padding: 3em 0em 1em 0em;
}

.serviceCol {
  padding: 1em;
}

.serviceCol-2 {
  border: 0.1em solid #dfdfdf;
  border-radius: 0.2em;
  transition: 0.2s;
  padding: 2.5em;
}

.serviceCol-2:hover {
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
}

@media (max-width: 1050px) {
  .serviceCol-2 {
    padding: 2em;
  }
}

.serviceImage {
  margin-top: 1em;
  border-radius: 0.3em;
}

.serviceP {
  line-height: 1.8em;
}

.serviceHeader {
  font-size: 1.2em;
  text-align: center;
}

/********* AdvertisementCarousel *********/

.carouselImage{
  width: 100%;
}

.carousel-caption {
  text-align: left;
  top: 5em;
}

.carouselHeader {
  margin: 0.6em 0em 0.4em -2.4em;
  padding: 0em;
  font-size: 2.2em;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
}

.carouselHeader-2 {
  text-align: center;
  margin: 1.5em 0em 0.4em 0em;
  padding: 0em;
  font-size: 2.2em;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
}

.carouselP {
  margin: 0.1em 0em 0.1em -4.4em;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 2em;
  color: #000000;
}

.carouselSecondP{
  margin: 0.1em 0em 0.1em -4.4em;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 2em;
  color: #000000;
}

.carouselP-2 {
  text-align: center;
  margin: 0.1em 0em 0.1em 0em;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 2em;
  color: #000000;
}

.carouselSecondP-2 {
  text-align: center;
  margin: 0.1em 0em 0.1em 0em;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 2em;
  color: #000000;
}

.carouselButton {
  margin: 0.6em 0em 1em -5.2em;
  background-color: #1ca097;
  border: 0.1em solid #1ca097;
  border-radius: 0.2em;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0.5em 1.5em 0.5em 1.5em;
  transition: 0.4s;
}

.carouselButton:hover,
.carouselButton:focus {
  background-color: #14847C;
  border: 0.1em solid #14847C;
  color: #ffffff;
}

.carouselButton-2 {
  margin: 0.6em 0em 1em -5.2em;
  background-color: #103882;
  border: 0.1em solid #103882;
  border-radius: 0.2em;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0.5em 1.5em 0.5em 1.5em;
  transition: 0.4s;
}

.carouselButton-2:hover,
.carouselButton-2:focus {
  background-color: #0c2656;
  border: 0.1em solid #0c2656;
  color: #ffffff;
}

@media (min-width: 0px) and (max-width: 360px){
  .carouselP {
    display: none;
  }
  
  .carouselSecondP {
    display: none;
  }

  .carouselSecondP-2 {
    display: none;
  }
  
  .carouselP-2 {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 450px){
  .carousel-caption {
    top: 1em;
    text-align: center;
  }

  .carouselHeader {
    margin: 0em 0em 0.3em 0em;
    font-size: 2em;
  }
  
  .carouselHeader-2 {
    margin: 3.5em 0em 0.6em 0em;
    font-size: 1.6em;
  }
  
  .carouselP {
    margin: 0.6em 0em 0.6em 0em;
    font-size: 1em;
  }
  
  .carouselSecondP {
    display: none;
  }

  .carouselSecondP-2 {
    display: none;
  }
  
  .carouselP-2 {
    margin: 0.2em 0em 0.6em 0em;
    font-size: 0.95em;
  }

  .carouselButton {
    margin: 0.4em 0em 0em 0em;
    padding: 0.4em 0.8em 0.4em 0.8em;
    font-size: 0.9em;
  }

  .carouselButton-2 {
    margin: 0.4em 0em 0em 0em;
    padding: 0.4em 0.8em 0.4em 0.8em;
    font-size: 0.9em;
  }
}

@media (min-width: 450px) and (max-width: 900px){
  .carousel-caption {
    top: 2em;
  }

  .carouselHeader {
    margin: 0em 0em 0.3em -3em;
    font-size: 1.4em;
  }
  
  .carouselHeader-2 {
    margin: 1em 0em 0.2em 0em;
    font-size: 1.3em;
  }
  
  .carouselP {
    margin: 0.15em 0em 0.15em -5.6em;
    font-size: 0.75em;
  }
  
  .carouselSecondP {
    margin: 0.15em 0em 0.15em -5.6em;
    font-size: 0.75em;
  }

  .carouselSecondP-2 {
    margin: 0.15em 0em 0.15em 0em;
    font-size: 0.75em;
  }
  
  .carouselP-2 {
    margin: 0.15em 0em 0.15em 0em;
    font-size: 0.75em;
  }

  .carouselButton {
    margin: 0.4em 0em 0em -6.5em;
    padding: 0.4em 0.8em 0.4em 0.8em;
    font-size: 0.65em;
  }

  .carouselButton-2 {
    margin: 0.4em 0em 0em -6.5em;
    padding: 0.4em 0.8em 0.4em 0.8em;
    font-size: 0.65em;
  }
}

@media (min-width: 900px) and (max-width: 1200px){
  .carousel-caption {
    top: 3.5em;
  }

  .carouselHeader {
    margin: 0em 0em 0.3em -2.8em;
    font-size: 1.8em;
  }
  
  .carouselHeader-2 {
    margin: 1em 0em 0.2em 0em;
    font-size: 1.6em;
  }
  
  .carouselP {
    margin: 0.15em 0em 0.15em -5.6em;
    font-size: 0.9em;
  }
  
  .carouselSecondP {
    margin: 0.15em 0em 0.15em -5.6em;
    font-size: 0.9em;
  }

  .carouselSecondP-2 {
    margin: 0.15em 0em 0.15em 0em;
    font-size: 0.9em;
  }
  
  .carouselP-2 {
    margin: 0.15em 0em 0.15em 0em;
    font-size: 0.9em;
  }

  .carouselButton {
    margin: 0.4em 0em 0em -5.6em;
    padding: 0.4em 0.8em 0.4em 0.8em;
    font-size: 0.9em;
  }

  .carouselButton-2 {
    margin: 0.4em 0em 0em -5.6em;
    padding: 0.4em 0.8em 0.4em 0.8em;
    font-size: 0.9em;
  }
}

@media (min-width: 1650px) and (max-width: 2050px) {
  .carousel-caption {
    top: 4.5em;
  }

  .carouselHeader {
    margin: 0.6em 0em 0.3em -2.2em;
    font-size: 3em;
  }
  
  .carouselHeader-2 {
    margin: 1.5em 0em 0.4em 0em;
    font-size: 3em;
  }
  
  .carouselP {
    margin: 0.2em 0em 0.2em -4.2em;
    font-size: 1.6em;
  }

  .carouselSecondP {
    margin: 0.2em 0em 0.2em -4.2em;
    font-size: 1.6em;
  }
  
  .carouselP-2 {
    margin: 0.2em 0em 0.2em 0em;
    font-size: 1.6em;
  }

  .carouselSecondP-2{
    margin: 0.2em 0em 0.2em 0em;
    font-size: 1.6em;
  }

  .carouselButton {
    margin: 0.6em 0em 0em -6.6em;
  }

  .carouselButton-2 {
    margin: 0.6em 0em 0em -6.6em;
  }
}

@media (min-width: 2050px) and (max-width: 2400px) {
  .carousel-caption {
    top: 8em;
  }

  .carouselHeader {
    margin: 0.6em 0em 0.3em -1.8em;
    font-size: 3em;
  }
  
  .carouselHeader-2 {
    margin: 1.5em 0em 0.6em 0em;
    font-size: 3em;
  }
  
  .carouselP {
    margin: 0.6em 0em 0.6em -3.2em;
    font-size: 1.6em;
  }

  .carouselSecondP {
    margin: 0.6em 0em 0.6em -3.2em;
    font-size: 1.6em;
  }
  
  .carouselP-2 {
    margin: 0.3em 0em 0.6em 0em;
    font-size: 1.6em;
  }

  .carouselSecondP-2{
    margin: 0.3em 0em 0.6em 0em;
    font-size: 1.6em;
  }
}

@media (min-width: 2400px) and (max-width: 10000px) {
  .carousel-caption {
    top: 8em;
  }

  .carouselHeader {
    margin: 0.6em 0em 0.3em -1.8em;
    font-size: 4em;
  }
  
  .carouselHeader-2 {
    margin: 1.5em 0em 0.6em 0em;
    font-size: 4em;
  }
  
  .carouselP {
    margin: 0.2em 0em 0.2em -3.6em;
    font-size: 2em;
  }

  .carouselSecondP {
    margin: 0.2em 0em 0.2em -3.6em;
    font-size: 2em;
  }
  
  .carouselP-2 {
    margin: 0.2em 0em 0.2em 0em;
    font-size: 2em;
  }

  .carouselSecondP-2{
    margin: 0.2em 0em 0.2em 0em;
    font-size: 2em;
  }

  .carouselButton {
    margin: 0.6em 0em 0em -4.7em;
    font-size: 1.5em;
  }

  .carouselButton-2 {
    margin: 0.6em 0em 0em -4.7em;
    font-size: 1.5em;
  }
}

/********* HomeScreen *********/
.homeCol-2 {
  padding: 1.5em;
}

.homeCol-3 {
  padding: 1em 1em 0.1em 1em;
}

.homeHeader {
  font-size: 1.8em;
  margin: 1.5em 0em 0.5em 0em;
  padding: 0em 0.2em 0em 0.2em;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
}

.homeHeader-3 {
  font-size: 1.8em;
  margin: 2em 0em 0.5em 0em;
  padding: 0em;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
}

.homeP {
  color: #000000;
  margin: 0em 0em 2em 0em;
  padding: 0em 1.5em 0em 1.5em;
  line-height: 2em;
}

.homeHeader-2 {
  font-size: 1.2em;
}

.homeP-2 {
  margin: 0em;
  line-height: 1.8em;
}

.homeSection {
  padding: 2em 0em 2em 0em;
  background-color: rgb(255, 111, 0, 0.3);
}

.homePopularImage {
  padding: 1em 2em 0em 2em;
}

.popularProductLink {
  padding: 1em;
}

.popularItem {
  border: 0.1em solid #dfdfdf;
  border-radius: 0.2em;
  transition: 0.2s;
  text-align: center;
  padding-bottom: 1em;
}

.popularItem:hover {
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
}

@media (min-width: 1300px) {
  .homePopularImage {
    padding: 1em 5em 0em 5em;
  }
}

.homePopularP {
  margin: 0em;
  padding: 1em 2em 1em 2em;
  text-align: left;
}

/********* All *********/

.customRow {
  padding: 0em;
}

@media (min-width: 992px) {
  .customRow {
    padding: 0em 4em 0em 4em;
  }
}

.customRow-2 {
  padding: 2em 1em 1em 1em;
}

@media (min-width: 992px) {
  .customRow-2 {
    padding: 4em 10em 2em 10em;
  }
}

.header-2 {
  font-size: 1.5em;
  font-weight: 400;
}

.textDecorationNone-2 {
  text-decoration: none;
  color: #000000;
}

.textDecorationNone-2:hover {
  text-decoration: underline;
  color: #000000;
}
